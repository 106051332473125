<template>
  <input
    :type="value.type || 'text'"
    v-model="inputValue"
    :placeholder="value.placeholder"
    class="border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent"
    @input="$emit('input', { inputValue, placeholder: value.placeholder, type: value.type })"
    @keypress.enter="$emit('enter')"
  />
</template>

<script>
export default {
  name: 'form-input',
  props: ['value', 'placeholder', 'type'],
  components: {},
  data() {
    return {
      inputValue: this.value.inputValue,
    }
  },
}
</script>
