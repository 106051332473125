<template>
  <div class="flex w-full justify-center items-center min-h-screen bg-gray-50">
    <div class="w-1/4 flex flex-col bg-white shadow p-6">
      <div class="mx-auto text-center text-2xl font-bold">
        <img src="@/assets/logo.svg" :alt="appName" />
      </div>

      <h1 class="text-center font-medium text-3xl">Passwort eingeben</h1>

      <h3 class="text-xs font-medium mt-4 mb-2">
        Bitte setzten Sie ein Passwort für Ihren Account
      </h3>

      <h3 v-if="error" class="text-center text-xs my-4 text-red-500">
        {{ error }}
      </h3>

      <div class="flex flex-col mt-4 text-sm">
        <div class="my-2">
          <FormInput v-model="password" />
        </div>

        <div class="my-2">
          <FormInput v-model="confirm_password" />
        </div>

        <div class="my-2">
          <button
            class="rounded bg-blue-500 w-full text-center py-2 text-white"
            @click.prevent="confirmInviteAndLogin"
          >
            Account erstellen
          </button>
        </div>

        <div class="mt-4">
          <router-link to="login" class="text-blue-400 text-center block"
            >Abbrechen</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { confirmInvite } from '@/services/auth'

import FormInput from '@/components/Inputs/FormInput'

export default {
  name: 'confirm-invitation',
  components: {
    FormInput,
  },
  title: 'Confirm Invitation',
  data() {
    return {
      password: {
        inputValue: '',
        type: 'password',
        placeholder: 'neues Passwort, mindestens 6 Zeichen.',
      },
      confirm_password: {
        inputValue: '',
        type: 'password',
        placeholder: 'Passwort bestätigen',
      },
      error: false,
    }
  },
  methods: {
    confirmInviteAndLogin() {
      const code = this.$route.query.code
      const client = this.$route.query.client

      if (
        this.password.inputValue === this.confirm_password.inputValue &&
        this.password.inputValue.length >= 6
      ) {
        confirmInvite(this.password.inputValue, code, client)
          .then(response => {
            if (response.status === 201) {
              this.$toast.success('Passwort erfolgreich aktualisiert')
              this.$router.push(`/login/?msg=Passwort erfolgreich aktualisiert`)
            }
          })
          .catch(err => {
            console.log('Err ::: ', err)
          })
      } else if (this.password.inputValue.length < 6) {
        this.error =
          'Passwort ist zu kurz, bitte geben Sie ein mindestens 6 Zeichen langes Passwort an.'
      } else {
        this.error =
          'Passwort nicht übereinstimmen, geben Sie das Passwort bitte erneut ein.'
      }
    },
  },
}
</script>

<style></style>
